import * as React from 'react';
import * as ReactDOM from 'react-dom';

import './css/index.scss';

let titles = {
    '0_0': ['Цифровизация структур', 'государственной власти'],
    '0_1': ['Комплексная цифровая', 'трансформация бизнеса'],
    '0_2': ['Аудит', 'IT-инфраструктуры'],
    '0_3': ['Создание сайтов', 'любой сложности'],
    '1_0': ['Разработка мобильных и', 'десктопных приложений'],
    '1_1': ['Интернет-маркетинг', 'и веб-аналитика'],
    '1_2': 'Кадровый консалтинг в IT',
    '1_3': 'IT аутсорсинг',
    '2_0': ['Повышение квалификации', 'разработчиков и сотрудников'],
    '2_1': ['Школа разработки и', 'карьерные консультации'],
    '2_2': ['Поддержка общественных', 'инициатив в области IT']
};

let ryba = ``;

let bodys = {
    '0_0': <>
        <div className='clause'>
            <span className='problem'><span className='problem'>Проблема</span></span>: проблем нет, есть задачи!
            <div style={{ fontStyle: 'italic' }}>&laquo;В наступающее десятилетие нам предстоит провести цифровую трансформацию всей страны&raquo;</div>
            <div style={{ marginLeft: 400 }}>В.В.Путин</div>
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: Россия должна стать лидером мировой IT-отрасли, все возможности для этого у нас есть.
        </div>
    </>,
    '0_1': <>
        <div className='clause'><span className='problem'>Проблема</span>: уже из каждого чайника говорят о цифровой трансформации. Кажется, первую бухгалтерскую программу мы внедрили еще при Советской власти,
            так о чем они все толкуют?</div>
        <div className='clause'><span className='solution'>Решение</span>: похоже, за последние годы действительно произошел "слом парадигмы", и окончательный переход в "постиндастриал". Цифровой арсенал
            стал несколько шире программ для бухучета. Обсудим, о каких инструментах вам стоит знать.</div>
    </>,
    '0_2': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: IT-инфраструктура вашей огранизации создавалась десятилетиями, и на текущий момент в один клубок завязаны решения разных поколений программ и людей...</div>
        <div className='clause'>
            <span className='solution'>Решение</span>: Работает - не трогай? Ну, ваш первый 486-й уже вероятно на свалке, так что тронуть придется. Проведем независимый аудит, понятными словами опишем текущую ситуацию и
            дадим рекомендации, куда двигаться дальше</div>
    </>,
    '0_3':
        <>
            <div className='clause'>
                <span className='problem'>Проблема</span>: на рынке тысячи веб-студий, и все обещают сделать "современный сайт". С 2000 года, кажется.</div>
            <div className='clause'>
                <span className='solution'>Решение</span>: давайте для начала просто поговорим о сайтах, какие они бывают, и что вы хотите (или что не так с) от своего представительства в Интернете.</div>
            Вас это ни к чему не обязывает.
        </>
    ,
    '1_0': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: говорят, в среднем, в броузере современного юзера открыто около 3000 вкладок. Ваш сайт может и потеряться.
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: а вот приложения работают и без броузеров, и даже без интернета! А если приложение уже есть - найдем что улучшить!
        </div>
    </>,
    '1_1': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: сайт есть, приложения работают, но RoI оставляет желать. Или ничего из этого нет, и непонятно с чего лучше начинать.
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: пришло время двигаться, опираясь на реальные данные. Проанализируем расклады и подскажем, что делать.
        </div>
    </>,
    '1_2': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: не уверены, что вам действительно нужны именно эти разработчики? Не важно работают они уже или еще только в проекте.
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: обращайтесь к независимым консультантам (т.е. к нам!). Проанализируем Ваши потребности, поможем с подбором или предложим варианты решений без найма в штат</div>
    </>,
    '1_3': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: надоели капризные и нелояльные мидлы и синьоры? Или еще не надоели, т.к. не можете их найти вообще.. А дальше будет только хуже...
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: аутсорс разработки. Специализированная экспертиза накапливается годами и десятилетями опыта в узких нишах. И в отличие от сотрудников, партнеры не станут увольняться в самый неподходящий момент.
        </div>
    </>,
    '2_0': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: не уверены, что сотрудники действительно в совершенстве владеют своими инструментами? Или наоборот, уверены, что не владеют?</div>
        <div className='clause'>
            <span className='solution'>Решение</span>: действительно, даже не каждого программиста можно назвать "уверенным пользователем ПК". Проводим обучение &mdash; от кратких семинаров "как эффективно использовать ваш компьютер"
            до трансформации "кодеров" в профессионалов, которые решают задачи бизнеса любой сложности</div></>,
    '2_1': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: требования к работникам в IT-сфере растут каждый день, и как за всем этим уследить?</div>
        <div className='clause'>
            <span className='solution'>Решение</span>: вместе поймем, в какой точке Вы сейчас находитесь, куда стоит стремиться, и что для этого нужно изучить
        </div></>,
    '2_2': <>
        <div className='clause'>
            <span className='problem'>Проблема</span>: скорее, Проблемы &mdash; кадровый кризис, отсутствие стандартов качества в индустрии, некомпетентное законотворчество...
        </div>
        <div className='clause'>
            <span className='solution'>Решение</span>: разработчики всей страны - объединяйтесь!
        </div>
    </>
};

window.onload = () => {
    ReactDOM.render(
        <Main />,
        document.getElementById('forReact') as HTMLElement
    );
}

const halfLine = 100; // 
const halfLineSec = Math.sqrt(3) / 2 * halfLine;
const startHor = 120;
const startVer = 120;
const qtyRows = 3;
const qtyCols = 3;

const texts = [...Array(qtyRows).keys()].map(i =>
    [...Array(qtyCols).keys()].map(j =>
        'Раздел ' + (i + 1) + '.' + (j + 1)
    )
);

type TTile = [number, number];
// type TLine = [number, number][];

interface IMainState {
    // lines: TLine[][][]
    // current: 
    hovered?: TTile,
    current?: [number, number]
}

class Main extends React.Component<{}, IMainState> {
    ref: HTMLElement;
    rect: DOMRect;

    constructor(props: {}) {
        super(props);

        this.state = {
            // lines
        }
    }

    getCenter(i: number, j: number) {
        const stepVert = 3;
        const stepHor = 4;
        let isOdd = i % 2;
        let shift = isOdd * 2;

        let res = [
            startHor + (j * stepHor + shift + 2) * halfLineSec,
            startVer + (i * stepVert + 1) * halfLine
        ];

        return res;
    }

    render() {

        let text = this.state.hovered ? texts[this.state.hovered[0]][this.state.hovered[1]] : null;

        let center;

        if (this.state.hovered) {
            center = this.getCenter(...this.state.hovered);
        }

        return (<div id="main">
            <div className="bigHeader">
                <div id="titleCont">
                    <div id="title">
                        <span style={{ fontSize: '60%' }}>Многопрофильный</span><br />
                        IT-холдинг
                        &laquo;Картвеб&raquo;
                    </div>
                    <div id="subtitle">
                        Будущее <span style={{ textDecoration: 'line-through' }}>рядом</span> уже ЗДЕСЬ.<br />
                        {/** Как и ваша прибыль. */}
                        Ваша прибыль ТОЖЕ.
                    </div>
                </div>
                <div
                    id="svgContainer"
                    ref={(node) => {
                        if (node) {
                            this.ref = node;
                            this.rect = node.getBoundingClientRect();
                        }
                    }}
                >
                    <svg
                        onMouseMove={(event) => {
                            // let rect = (event.target as HTMLElement).getBoundingClientRect();
                            // console.log(rect); // сбрасывается target
                            // console.log(this.rect);
                            this.setState({
                                current: [event.clientX - this.rect.left, event.clientY - this.rect.top]
                            })
                        }}
                    >

                        {/* <filter id="shadow">
                            <feMorphology operator="dilate" radius="5"></feMorphology>
                            <feGaussianBlur stdDeviation="3"></feGaussianBlur>
                            <feComponentTransfer result="blur">
                                <feFuncA type="linear" slope="0.5"></feFuncA>
                            </feComponentTransfer>
                            <feMerge>
                                <feMergeNode in="blur"></feMergeNode>
                                <feMergeNode in="SourceGraphic"></feMergeNode>
                            </feMerge>
                        </filter> */}

                        {/* {this.state.current ?
                            <line
                                x1={this.state.current[0]}
                                y1={this.state.current[1]}
                                x2={50 + 200}
                                y2={700}
                                className={'pointer'}
                            /> : null} */}
                        {[...Array(qtyRows).keys()].map(i => {
                            return [...Array(qtyCols).keys()].map(j => {
                                {/* немного другой вар-то развертки
                                let points = (startHor + j * 6 * halfLine) + ',' + (startVer + i * 4 * halfLineVert) + ' ';
                                points += (startHor + (j * 6 + 2) * halfLine) + ',' + (startVer + (i * 4 * halfLineVert)) + ' ';
                                points += (startHor + (j * 6 + 3) * halfLine) + ',' + (startVer + ((i * 4 + 2) * halfLineVert)) + ' ';
                                points += (startHor + (j * 6 + 2) * halfLine) + ',' + (startVer + ((i * 4 + 4) * halfLineVert)) + ' ';
                                points += (startHor + j * 6 * halfLine) + ',' + (startVer + (i * 4 + 4) * halfLineVert) + ' ';
                                points += (startHor + (j * 6 - 1) * halfLine) + ',' + (startVer + ((i * 4 + 2) * halfLineVert)) + ' ';
                                */}

                                let centerLoc = this.getCenter(i, j);

                                const stepVert = 3;
                                const stepHor = 4;
                                let isOdd = i % 2;
                                let isEven = +!isOdd;
                                let shift = isOdd * 2;

                                let points = [];
                                points.push([startHor + (j * stepHor + shift) * halfLineSec, startVer + (i * stepVert) * halfLine]);
                                points.push([startHor + (j * stepHor + 2 + shift) * halfLineSec, startVer + (i * stepVert - 1) * halfLine]);
                                points.push([startHor + (j * stepHor + 4 + shift) * halfLineSec, startVer + (i * stepVert) * halfLine]);
                                points.push([startHor + (j * stepHor + 4 + shift) * halfLineSec, startVer + (i * stepVert + 2) * halfLine]);
                                points.push([startHor + (j * stepHor + 2 + shift) * halfLineSec, startVer + (i * stepVert + 3) * halfLine]);
                                points.push([startHor + (j * stepHor + shift) * halfLineSec, startVer + (i * stepVert + 2) * halfLine]);

                                let pointsAttr = points.map(p => p[0] + ',' + p[1]).join(' ');
                                let isHovered = (this.state.hovered && i === this.state.hovered[0] && j === this.state.hovered[1]);

                                let isHoveredArr = [];
                                isHoveredArr[0] = (this.state.hovered && i === this.state.hovered[0] + 1 && j === this.state.hovered[1] + isEven);
                                isHoveredArr[1] = (this.state.hovered && i === this.state.hovered[0] + 1 && j === this.state.hovered[1] - 1 + isEven);
                                isHoveredArr[2] = (this.state.hovered && i === this.state.hovered[0] && j === this.state.hovered[1] - 1);

                                // if (!i && !j) {
                                //     return <g>
                                //         {
                                //             <polygon
                                //                 points={pointsAttr}
                                //                 style={{ stroke: 'white', zIndex: 500 }}
                                //                 strokeWidth={1}
                                //             />
                                //         }
                                //     </g>
                                // } else {
                                //     return null;
                                // }

                                let textProps = {
                                    x: centerLoc[0],
                                    y: centerLoc[1],
                                    stroke: 'white',
                                    fill: 'white',
                                    style: {
                                        textAnchor: 'middle' as any,
                                        dominantBaseline: 'middle' as any,
                                        fontFamily: 'Century Gothic',
                                        // fontFamily: 'Proxon',
                                        fontSize: 24
                                    }
                                };

                                let dh = 20;

                                return <g key={'g_' + i + '_' + j}
                                >
                                    {/* {center ? <circle
                                        cx={center[0]}
                                        cy={center[1]}
                                        r={2}
                                        stroke={'white'}
                                        fill={'white'}
                                    /> : null} */}
                                    {/* <circle
                                        cx={centerLoc[0]}
                                        cy={centerLoc[1]}
                                        r={2}
                                        stroke={'lightblue'}
                                        fill={'lightblue'}
                                    /> */}
                                    {!titles[i + '_' + j] || typeof titles[i + '_' + j] === 'string' ?
                                        (<text
                                            className={'text'}
                                            {...textProps as any}
                                        >
                                            {titles[i + '_' + j]}
                                        </text>) : (
                                            <g>
                                                <text
                                                    className={'text'}
                                                    {...textProps as any}
                                                    dy={-dh}
                                                >
                                                    {titles[i + '_' + j][0]}
                                                </text>
                                                <text
                                                    className={'text'}
                                                    {...textProps as any}
                                                    dy={dh}
                                                >
                                                    {titles[i + '_' + j][1]}
                                                </text>
                                            </g>
                                        )}

                                    {/* <g requiredFeatures="http://www.w3.org/Graphics/SVG/feature/1.2/#TextFlow"> */}
                                    {/* {React.createElement('textArea', { / * width: 200, height: 300, * / ...textProps }, ['whatever'])} */}

                                    {/* <foreignObject
                                            {...textProps}
                                            width="100"
                                            height="150"
                                        >
                                            <div
                                                {...{ xmlns: "http://www.w3.org/1999/xhtml" }}
                                                style={{ color: 'white' }}
                                            >
                                                Here is a <strong>paragraph</strong> that requires <em>word
                                                    wrap</em> and has much more text to wrap and sence
                                                to wrappin well
                                            </div>
                                        </foreignObject> */}
                                    {/* </g> */}

                                    <polygon
                                        className="hoverPolygon"
                                        points={pointsAttr}
                                        style={{
                                            strokeOpacity: isHovered ? 1 : 0,
                                            fillOpacity: isHovered ? 0 : 0,
                                        }}
                                        onClick={(event) => {
                                            // location.hash = 'sectorContainer_' + i + '_' + j;
                                            document.querySelector('#sectorContainer_' + i + '_' + j).scrollIntoView({
                                                behavior: 'smooth'
                                            })
                                        }}
                                        onMouseOver={(event) => {
                                            // (event.target as any).style.stroke = 'blue';
                                            // (event.target as any).style.zIndex = 10;
                                            this.setState({
                                                hovered: [i, j]
                                            });
                                        }}
                                        onMouseOut={(event) => {
                                            // (event.target as any).style.stroke = 'white';
                                            // (event.target as any).style.zIndex = null;
                                            this.setState({
                                                hovered: null
                                            });
                                        }}
                                    />
                                    {isHovered ?
                                        <>
                                            <polyline
                                                className="underPolyline"
                                                points={[points[3], points[4]].map(p => p[0] + ',' + p[1]).join(' ')}
                                            />
                                            <polyline
                                                className="underPolyline"
                                                points={[points[4], points[5]].map(p => p[0] + ',' + p[1]).join(' ')}
                                            />
                                            <polyline
                                                className="underPolyline delay"
                                                points={[points[3], points[4]].map(p => p[0] + ',' + p[1]).join(' ')}
                                            />
                                            <polyline
                                                className="underPolyline delay"
                                                points={[points[4], points[5]].map(p => p[0] + ',' + p[1]).join(' ')}
                                            />
                                        </>
                                        : null}
                                    {[...Array(3).keys()].map(k =>
                                        <line
                                            key={'l_' + i + '_' + j + '_' + k}
                                            x1={points[k][0]}
                                            y1={points[k][1]}
                                            x2={points[k + 1][0]}
                                            y2={points[k + 1][1]}
                                            style={{ strokeOpacity: isHovered || isHoveredArr[k] ? 0 : 1 }}
                                            className={'side'}
                                        />
                                    )}
                                    {!j ?
                                        <>
                                            {(isEven ?
                                                <line
                                                    x1={points[4][0]}
                                                    y1={points[4][1]}
                                                    x2={points[5][0]}
                                                    y2={points[5][1]}
                                                    style={{ strokeOpacity: isHovered ? 0 : 1 }}
                                                    className={'side'}
                                                />
                                                : null)}
                                            <line
                                                x1={points[5][0]}
                                                y1={points[5][1]}
                                                x2={points[0][0]}
                                                y2={points[0][1]}
                                                style={{ strokeOpacity: isHovered ? 0 : 1 }}
                                                className={'side'}
                                            />
                                        </>
                                        : null}
                                </g>
                                {/* // return <g>
                                //     <line key={'1_' + j} x1={startHor + j * 6 * halfLine} y1={startVer + i * 4 * halfLine} x2={startHor + j * 6 * halfLine + 2 * halfLine} y2={startVer + i * 4 * halfLine} />
                                //     <line key={'2_' + j} x1={startHor + (j * 6 + 3) * halfLine} y1={startVer + (i * 4 + 2) * halfLine} x2={startHor + (j * 6 + 5) * halfLine} y2={startVer + (i * 4 + 2) * halfLine} />

                                //     <line key={'3_' + j} x1={startHor + (j * 6 + 3) * halfLine} y1={startVer + (i * 4 + 2) * halfLine} x2={startHor + j * 6 * halfLine + 2 * halfLine} y2={startVer + i * 4 * halfLine} />
                                //     <line key={'4_' + j} x1={startHor + (j * 6) * halfLine} y1={startVer + i * 4 * halfLine} x2={startHor + (j * 6 - 1) * halfLine} y2={startVer + (i * 4 + 2) * halfLine} />

                                //     <line key={'5_' + j} x1={startHor + (j * 6 + 3) * halfLine} y1={startVer + (i * 4 + 2) * halfLine} x2={startHor + j * 6 * halfLine + 2 * halfLine} y2={startVer + (i + 1) * 4 * halfLine} />
                                //     <line key={'6_' + j} x1={startHor + (j * 6) * halfLine} y1={startVer + (i + 1) * 4 * halfLine} x2={startHor + (j * 6 - 1) * halfLine} y2={startVer + (i * 4 + 2) * halfLine} />
                                // </g> */}
                            })
                        })}
                        {/* @@## <g>
                            <rect
                                x={50}
                                y={700}
                                width={400}
                                height={250}
                                stroke="white"
                                fill="black"
                                rx={5}
                                ry={5}
                                z={1000}
                            // fill="red"
                            // style={{ width: 300, height: 300, backgroundColor: 'red' }}
                            />
                            <text
                                x={50}
                                y={700}
                                className={'text'}
                                dx={'5px'}
                                dy={'20px'}
                                z={5}
                            >
                                {text}
                            </text>
                        </g> */}
                    </svg>
                </div>
            </div>
            <div id="content">
                {
                    Object.keys(titles).map(key => {
                        let title = Array.isArray(titles[key]) ? titles[key].join(' ') : titles[key]

                        return <div
                            className='sectorContainer'
                            id={'sectorContainer_' + key}
                            key={key}
                        >
                            <div
                                className='sectorTitle'
                            >
                                {title}
                            </div>
                            <div
                                className='sectorBody'
                                dangerouslySetInnerHTML={typeof bodys[key] === 'string' ? { __html: bodys[key] } : null}
                            >
                                {typeof bodys[key] !== 'string' ? bodys[key] : null}
                            </div>
                        </div>
                    })}
            </div>
        </div>);
    }

    componentDidMount() {

    }
}